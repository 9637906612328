@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&family=Merriweather+Sans&family=Oswald:wght@700&display=swap');
$body-bg: #13151b;
$body-bg-watermark: #192732;
$body-color: #fbf6f2;
$primary: #b551b8;
$primary-muted: #4b345b;
$secondary: #54b0a7;
$secondary-muted: hsl(174, 28%, 30%);
$danger: #ff4136;
$font-a: 'Merriweather Sans',
sans-serif;
$font-b: 'Oswald',
sans-serif;
$font-c: 'Cutive Mono',
sans-serif;


// Full site
html,
body,
#root,
.App {
    max-width: 100vw;
    overflow-x: hidden;
}

body {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: auto;
}

p {
    font-family: 'Merriweather Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Oswald', sans-serif;
}

.btn {
    margin-top: 1em;
}

.btn:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.code-text {
    font-family: 'Cutive Mono', monospace;
}

.container-shadow {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    height: 100%;
    margin: auto;
}

.fullwidth {
    min-height: 100vh;
    max-width: 100vw;
    display: grid;
    padding: 0;
    margin: 0;

}

// NAVBAR
.navbar {
    z-index: 25;
    height: 4em;
}

.navbar-dark {
    color: $body-color;
    background-color: $body-bg-watermark;
}

.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navbar-nav {
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.navbar-collapse {
    background-color: $body-bg-watermark;
}

#navbarNavAltMarkup {
    .navbar-nav {
        text-align: center;

        .nav-link {
            color: $body-color;
            opacity: 0.7;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0 2em;
            border-bottom: 3px solid transparent;
            min-width: 4em;
            text-align: center;
            background-image: linear-gradient($primary, $primary);
            background-size: 100% 0.2em;
            background-position-y: 100%;
            background-repeat: no-repeat;
            transition: 0.2s ease-in-out;
        }

        .nav-link:focus,
        .nav-link:hover {
            color: $body-color;
            opacity: 1;
            background-size: 100% 100%;
        }

        .active {
            background-image: linear-gradient($secondary, $secondary);
            background-size: 100% 20%;
            opacity: 1;
        }

        .active:hover {
            background-size: 100% 50%;
        }
    }

}

// HOME
.title {
    color: #13151b;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.1em;
}



.hero {
    display: flex;
    height: 100%;
    width: 100%;
    background-image: url("../public/images/DSC09551.JPG");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: right;
    background-size: cover;
    padding: 0;
}

.hero-overlay {
    display: grid;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(108deg, $secondary 45%, transparent 45%), linear-gradient(108deg, rgba(84, 176, 167, 0.6) 50%, transparent 50%), linear-gradient(108deg, rgba(84, 176, 167, .4) 55%, transparent 55%), linear-gradient(108deg, rgba(84, 176, 167, .2) 60%, transparent 60%);
    border-bottom: #13151b solid 4px;
}

.hero-overlay-pink {
    display: grid;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(36deg, $primary 0%, rgba(0, 0, 0, 1) 100%);
    mix-blend-mode: difference;
}

.hero-overlay-black {
    display: grid;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(27deg, rgb(255, 0, 0) 0%, rgb(0, 216, 0) 100%);
    mix-blend-mode: difference;
}

.hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    // border: #13151b 1px solid;
}

.fullwidth {
    min-height: 100vh;
}

.section1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    max-width: 100vw;
    background-color: $body-bg;
}

.section2 {
    height: 100vh;
    background-color: black;
}

.section3 {
    height: 100vh;
}

// CV 

.container-cv {
    padding: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

.rightborder {
    border-right: $primary 2px solid;
}

.logoimg {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: white 2px solid;
    height: 3em
}

.entry h5 {
    padding-left: 0.4em;
    border-left: $primary 0.4em solid;
}

.entry p {
    padding-left: 1em;
}

.datespace {
    display: flex;
    flex-direction: column-reverse;
}

.date {
    margin-right: 1em;
    padding-right: 0.4em;
    border-right: $secondary 4px solid;
}

.cv-paragraph {
    margin: 1em;
}

// PROJECTS

.card {
    width: 18rem;
    margin: 1em;
}

.projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.buttonsdiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card-body {
    background-color: $body-bg-watermark;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.entry {
    margin-bottom: 2em;
}

// scrollbar
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: rgba(255, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $secondary;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $secondary-muted;
}

// FOOTER

footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 25vh;
    z-index: 15;
    background-color: $body-bg-watermark;
}

.linksdiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    a {
        margin-right: 1em;
        font-size: 1.5em;
    }
}

@media (max-width: 768px) {
    .section1 {
        min-height: 100vh;
        height: auto;
    }

    .footer {
        width: 100vw;
    }

    html,
    body,
    #root,
    .App {
        max-width: 100vw;
        overflow-x: hidden;
    }
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
@import "~bootstrap/scss/bootstrap.scss";